<template>
  <li>
    <a href="javascript:void(0)"
       class="relative flex py-3 hover:bg-gray-100 rounded-md px-2 mb-2 space-x-4 items-center">
      <div class="h-12 w-12 flex-shrink-0">
        <img
            :src="user && user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
            class="rounded-full object-cover h-full w-full">
      </div>
      <span class="bg-blue-500 absolute mt-8 ml-7 text-white p-2 rounded-full w-7 h-7 flex items-center">
        <i class="icon-feather-plus"></i>
      </span>
      <div>
        +{{ point.point }} điểm
      </div>
      <div class="mr-3">
        {{ point.reason }} <time>{{ timeago(point.created_at) }}</time>
      </div>
    </a>
  </li>
</template>

<script>
import {timeago} from "../../core/services/utils.service";

export default {
  name: "PointItem",
  props: {
    point: Object,
    user: Object
  },
  methods: {
    timeago(x) {
      return timeago(x);
    }
  }
}
</script>
