<template>
  <li>
    <a href="javascript:void(0)" v-on:click="goToActivity(activity)"
       class="relative flex py-3 hover:bg-gray-100 rounded-md px-2 mb-2 space-x-4 items-center">
      <div class="h-12 w-12 flex-shrink-0">
        <img
            :src="user && user.avatar ? user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
            class="rounded-full object-cover h-full w-full">
      </div>
      <div class="">
        {{ ucfirst(getActivityActionName(activity.action)) }}
        <span class="font-semibold mr-3"
              v-if="activity.object && (activity.action === 'update_lyric' || activity.action === 'create_sheet')">{{
            activity.object.title
          }}</span>
        <time>{{ timeago(activity.created_at) }}</time>
      </div>
    </a>
  </li>
</template>

<script>
import {
  getActivityActionName,
  getNotificationRouteName,
  getNotificationRouteParams,
  timeago,
  ucfirst
} from "../../core/services/utils.service";

export default {
  name: "ActivityItem",
  props: {
    activity: Object,
    user: Object
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    getActivityActionName(action) {
      return getActivityActionName(action);
    },
    ucfirst(x) {
      return ucfirst(x);
    },
    goToActivity(activity) {
      if (activity.object) {
        const type = activity.object["__typename"].toLowerCase();
        this.$router.push({
          name: getNotificationRouteName(type),
          params: getNotificationRouteParams(type, activity.object)
        });
      }
    },
  }
}
</script>
