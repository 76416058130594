<template>
  <div v-if="document.id" class="flex items-center space-x-4 py-3 hover:bg-gray-100 rounded-md -mx-2 px-2">
    <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
      <router-link :title="document.title" class="text-lg font-semibold" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}">
        <img :alt="document.title" :src="document.thumbnail ? document.thumbnail.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </router-link>
    </div>
    <div class="flex-1">
      <router-link :title="document.title" class="text-lg font-semibold" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}">
        {{ document.title }}
      </router-link>
      <div class="text-sm text-gray-500 mt-0.5">
        Tư liệu
      </div>
      <div class="text-sm text-gray-500 mt-1" :title="thousandFormat(document.views) + ' lượt xem'">
        <i class="uil uil-headphones"></i> {{ numberFormat(document.views) }}
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormat, thousandFormat } from "../core/services/utils.service";

export default {
  name: "DocumentItem",
  props: {
    document: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  methods: {
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  }
}
</script>
